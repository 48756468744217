/**
 * Top
 * -----------------------------------------------------------------------------
 */
/**
 * Setting
 * -----------------------------------------------------------------------------
 */
/**
 * Mixin
 * -----------------------------------------------------------------------------
 */
.MainContants {
  padding-top: 0;
  padding-bottom: 220px; }
  @media only screen and (max-width: 768px) {
    .MainContants {
      padding-bottom: 120px; } }

body {
  transition: opacity 1500ms cubic-bezier(0.39, 0.575, 0.565, 1); }

[data-cookie="true"] ._Intro {
  display: none; }

[data-cookie="false"].is-load ._Intro {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1200ms cubic-bezier(0.39, 0.575, 0.565, 1) 6500ms, visibility 0ms ease 8000ms; }

._Intro__logo {
  opacity: 0; }
  [data-cookie="false"].is-load ._Intro__logo {
    opacity: 1;
    transition: opacity 1500ms cubic-bezier(0.39, 0.575, 0.565, 1) 2200ms; }
  [data-cookie="false"].is-load ._Intro__logo::after {
    width: 93px;
    opacity: 0;
    transition: width 1500ms cubic-bezier(0.215, 0.61, 0.355, 1) 4000ms, opacity 800ms ease 5500ms; }

[data-cookie="false"] .Header {
  opacity: 0;
  transition: opacity 900ms cubic-bezier(0.39, 0.575, 0.565, 1) 8500ms; }

[data-cookie="false"].is-load .Header {
  opacity: 1; }

[data-cookie="false"] ._Hero__lead > .-line {
  opacity: 0;
  -webkit-transform: translateY(20px);
          transform: translateY(20px); }

[data-cookie="false"].is-load ._Hero__lead > .-line {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0); }

[data-cookie="false"].is-load ._Hero__lead > .-line1 {
  transition: opacity 900ms cubic-bezier(0.39, 0.575, 0.565, 1) 8500ms, -webkit-transform 900ms cubic-bezier(0.39, 0.575, 0.565, 1) 8500ms;
  transition: opacity 900ms cubic-bezier(0.39, 0.575, 0.565, 1) 8500ms, transform 900ms cubic-bezier(0.39, 0.575, 0.565, 1) 8500ms;
  transition: opacity 900ms cubic-bezier(0.39, 0.575, 0.565, 1) 8500ms, transform 900ms cubic-bezier(0.39, 0.575, 0.565, 1) 8500ms, -webkit-transform 900ms cubic-bezier(0.39, 0.575, 0.565, 1) 8500ms; }

[data-cookie="false"].is-load ._Hero__lead > .-line2 {
  transition: opacity 900ms cubic-bezier(0.39, 0.575, 0.565, 1) 8700ms, -webkit-transform 900ms cubic-bezier(0.39, 0.575, 0.565, 1) 8700ms;
  transition: opacity 900ms cubic-bezier(0.39, 0.575, 0.565, 1) 8700ms, transform 900ms cubic-bezier(0.39, 0.575, 0.565, 1) 8700ms;
  transition: opacity 900ms cubic-bezier(0.39, 0.575, 0.565, 1) 8700ms, transform 900ms cubic-bezier(0.39, 0.575, 0.565, 1) 8700ms, -webkit-transform 900ms cubic-bezier(0.39, 0.575, 0.565, 1) 8700ms; }

._Intro {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  background-image: url(/cms/wp-content/themes/moss/assets/images/pages/top/Intro/img.jpg);
  background-size: cover; }
  ._Intro__logo {
    position: absolute;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    overflow: hidden; }
    ._Intro__logo::after {
      content: "";
      position: absolute;
      width: calc(100% + 12px);
      height: 2px;
      left: calc(50% - 6px);
      bottom: 0;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      background-color: #aaa; }
    ._Intro__logo > img {
      display: block; }

._Hero {
  position: relative;
  height: 100vh; }
  @media only screen and (max-width: 768px) {
    ._Hero {
      pointer-events: none; } }
  ._Hero__lead {
    position: absolute;
    left: 18.75%;
    top: 26.66667%;
    z-index: 2;
    font-size: 11px;
    line-height: 1.75;
    letter-spacing: 0.05em;
    color: #fff; }
    @media only screen and (max-width: 768px) {
      ._Hero__lead {
        top: 50%;
        left: 45px;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%); } }
    ._Hero__lead > .-line {
      display: block; }
  ._Hero__listWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1; }
  ._Hero__listInner {
    position: relative;
    height: 100%; }
  ._Hero__list {
    height: 100%; }
    ._Hero__list > div {
      height: 100%; }
  ._Hero__listItem {
    width: 100%;
    height: 100%; }
    ._Hero__listItem img {
      width: 100%;
      height: 100vh;
      -o-object-fit: cover;
         object-fit: cover;
      font-family: 'object-fit: cover;'; }

._About {
  width: 75%;
  max-width: 1080px;
  margin: 0 auto;
  padding: 180px 0; }
  @media only screen and (max-width: 768px) {
    ._About {
      width: 76%;
      padding-top: 100px;
      padding-bottom: 100px; } }
  ._About__content > a {
    display: -webkit-flex;
    display: flex; }
    @media only screen and (max-width: 768px) {
      ._About__content > a {
        display: block; } }
    ._About__content > a:hover {
      opacity: 1; }
      @media only screen and (min-width: 320px) {
        ._About__content > a:hover ._About__text {
          opacity: 0.5; } }
  ._About__image {
    width: 50%; }
    @media only screen and (max-width: 768px) {
      ._About__image {
        width: 100%; } }
    ._About__image > img {
      width: 100%;
      height: auto;
      display: block; }
  ._About__text {
    width: 50%;
    padding-left: 16.66667%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    box-sizing: border-box;
    transition: opacity 500ms; }
    @media only screen and (max-width: 768px) {
      ._About__text {
        width: 100%;
        margin-top: 56px;
        padding-left: 0;
        display: block; } }
  ._About__leadEn {
    display: block;
    font-size: 13px;
    line-height: 2.5;
    letter-spacing: 0.05em; }
  ._About__leadJp {
    display: block;
    margin-top: 24px;
    font-size: 13px;
    line-height: 3;
    letter-spacing: 0.075em; }
  ._About__more {
    margin-top: 38px;
    width: 29px;
    height: 4px;
    display: inline-block;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    background-image: url(/cms/wp-content/themes/moss/assets/images/common/icon_arrow.svg); }

._Works {
  width: 95.55556%;
  margin: 0 auto; }
  @media only screen and (max-width: 768px) {
    ._Works {
      width: 100%; } }
  ._Works__moreLink {
    margin-top: 64px;
    padding-top: 164px;
    text-align: center;
    position: relative; }
    @media only screen and (max-width: 768px) {
      ._Works__moreLink {
        margin-top: 48px;
        padding-top: 95px; } }
    ._Works__moreLink::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      width: 1px;
      height: 140px;
      background-color: #E2E6E4; }
      @media only screen and (max-width: 768px) {
        ._Works__moreLink::before {
          height: 80px; } }
    ._Works__moreLink > a {
      font-size: 12px;
      line-height: 1;
      letter-spacing: 0.05em; }

._WorksItem:not(:first-child) {
  margin-top: 120px; }
  @media only screen and (max-width: 768px) {
    ._WorksItem:not(:first-child) {
      margin-top: 64px; } }

._WorksItem > a {
  opacity: 1; }
  ._WorksItem > a:hover {
    opacity: 1; }
    ._WorksItem > a:hover ._WorksItem_content {
      transition: opacity 0.5s; }
      @media only screen and (min-width: 320px) {
        ._WorksItem > a:hover ._WorksItem_content {
          opacity: 0.5; } }
    ._WorksItem > a:hover ._WorksItem__image::before {
      opacity: 1; }

._WorksItem__image {
  position: relative;
  z-index: 1; }
  ._WorksItem__image::before {
    content: "";
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, #1c2e20, transparent 70%);
    opacity: 0;
    transition: opacity 0.5s; }
  ._WorksItem__image > img {
    width: 100%;
    height: auto;
    display: block; }

._WorksItem_content {
  margin-top: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  transition: opacity 500ms; }
  @media only screen and (max-width: 768px) {
    ._WorksItem_content {
      margin-top: 16px;
      padding: 0 20px;
      box-sizing: border-box; } }

._WorksItem__text {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

._WorksItem__title {
  font-size: 13px;
  line-height: 1;
  letter-spacing: 0.075em; }

._WorksItem__category {
  margin-left: 10px;
  padding-left: 10px;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.075em;
  position: relative; }
  ._WorksItem__category::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 1px;
    height: 10px;
    background-color: #707070; }

._WorksItem__more {
  width: 29px;
  height: 4px;
  display: inline-block;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  background-image: url(/cms/wp-content/themes/moss/assets/images/common/icon_arrow.svg); }
  @media only screen and (max-width: 768px) {
    ._WorksItem__more {
      display: none; } }

._News {
  width: 62.5%;
  max-width: 900px;
  padding-top: 220px;
  margin: 0 auto;
  display: -webkit-flex;
  display: flex; }
  @media only screen and (max-width: 768px) {
    ._News {
      width: 89.33333%;
      padding-top: 165px;
      display: block; } }
  ._News__title {
    width: 180px;
    font-size: 16px;
    line-height: 1.42188;
    letter-spacing: 0.075em; }
    @media only screen and (max-width: 768px) {
      ._News__title {
        width: auto;
        font-size: 18px;
        line-height: 1;
        text-align: center; } }
  ._News__content {
    width: calc(100% - 180px); }
    @media only screen and (max-width: 768px) {
      ._News__content {
        width: 100%;
        margin-top: 54px; } }
    ._News__content > dl {
      margin-top: -12px; }
      ._News__content > dl > div > a {
        display: -webkit-flex;
        display: flex;
        padding: 12px 0;
        position: relative;
        transition: background-color 500ms; }
        @media only screen and (min-width: 320px) {
          ._News__content > dl > div > a:hover {
            opacity: 1;
            background-color: #EDEFEE; } }
        ._News__content > dl > div > a > dt,
        ._News__content > dl > div > a > dd {
          font-size: 13px;
          line-height: 1.75;
          letter-spacing: 0.075em; }
        ._News__content > dl > div > a > dt {
          width: 110px; }
          @media only screen and (max-width: 768px) {
            ._News__content > dl > div > a > dt {
              width: 85px; } }
        ._News__content > dl > div > a > dd {
          width: calc(100% - 110px); }
          @media only screen and (max-width: 768px) {
            ._News__content > dl > div > a > dd {
              width: calc(100% - 85px); } }
  ._News__moreLink {
    text-align: right; }
    ._News__moreLink > a {
      width: 29px;
      height: 4px;
      display: inline-block;
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
      background-image: url(/cms/wp-content/themes/moss/assets/images/common/icon_arrow.svg); }
